import axiosInterface from '../../axios/axios-instance';
import {toastError, toastSuccess} from '../../../components/notifications';
import {loggingUtil} from '../../../utils';

const CONTACT_ENDPOINT = '/api/v1/contacts';

class ContactService {

    createContact(contact) {
        return axiosInterface.post(CONTACT_ENDPOINT, contact)
            .catch(error => {
                loggingUtil.error(`An error has occurred when creating contact: ${error}`);
                throw error;
            });
    }

    updateContact(contactId, contact) {
        return axiosInterface.put(`${CONTACT_ENDPOINT}/${contactId}`, contact)
            .catch(error => {
                loggingUtil.error(`An error has occurred when updating contact. Id: [${contactId}]: ${error}`);
                throw error;
            });
    }

    retrieveContact(contactId) {
        return axiosInterface.get(`${CONTACT_ENDPOINT}/${contactId}`)
            .then(response => {
                return response;
            })
            .catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading contact. Id: [${contactId}]: ${error}`);
                throw error;
            });
    }

    transferContact(contactTransferRequest) {
        return axiosInterface.post(`${CONTACT_ENDPOINT}/transfer`, contactTransferRequest)
            .catch(error => {
                loggingUtil.error(`An error has occurred when transferring contact. ${error}`);
                throw error;
            });
    }

    getPageableContacts(params) {
        return axiosInterface.get(`${CONTACT_ENDPOINT}`, {params})
            .then(response => {
                return response;
            }).catch(error => {
                toastError({statusMessage: 'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when loading contacts: ${error}`);
                throw error;
            });
    }

    archiveContacts(contacts, archiveReason) {
        const promises = contacts.map(contact => axiosInterface.delete(`${CONTACT_ENDPOINT}/${contact.id}`, {data: archiveReason}));

        return Promise.all(promises)
            .then(() => {
                toastSuccess({statusMessage: 'account:account.archive.success.message'});
            })
            .catch(error => {
                toastError({statusMessage:'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when archiving a contact. ${error}`);
                throw error;
            });
    }

    unarchiveContacts(contacts) {
        const promises = contacts.map(contact => axiosInterface.put(`${CONTACT_ENDPOINT}/${contact.id}/unarchive`));

        return Promise.all(promises)
            .then(() => {
                toastSuccess({statusMessage:'account:account.restored.success.message'});
            })
            .catch(error => {
                toastError({statusMessage:'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when archiving an user. ${error}`);
                throw error;
            });
    }

    activateContacts(contacts) {
        const allPromises = contacts.map(contact => axiosInterface.put(`${CONTACT_ENDPOINT}/${contact.salesforceId}/activate`));

        return Promise.all(allPromises)
            .then(() => {
                toastSuccess({statusMessage:'user:user.activate.success.message'});
            })
            .catch(error => {
                toastError({statusMessage:'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when activating users. ${error}`);
                throw error;
            });
    }

    deactivateContacts(contacts) {
        const allPromises = contacts.map(contact => axiosInterface.put(`${CONTACT_ENDPOINT}/${contact.salesforceId}/deactivate`));

        return Promise.all(allPromises)
            .then(() => {
                toastSuccess({statusMessage:'user:user.deactivate.success.message'});
            })
            .catch(error => {
                toastError({statusMessage:'common:common.an.error.occurred', label: error});
                loggingUtil.error(`An error has occurred when deactivating users. ${error}`);
                throw error;
            });
    }

    getContactCSV = (params) => {
        return axiosInterface.get(`${CONTACT_ENDPOINT}/csv`, {params})
            .then(response => {
                return response;
            });
    };
}

const contactService = new ContactService()
export default contactService;